export function getSsoClientId(): string {
  const { origin } = window.location;

  if (origin.includes('localhost')) {
    const clientId = process.env['SSO_CLIENT_ID'];
    if (!clientId) {
      throw new Error('process.env.SSO_CLIENT_ID is undefined!');
    }

    return clientId;
  }

  if (origin.includes('app.pwi.pushwoosh')) {
    return 'APP_FRONT_PWI';
  }

  if (origin.includes('app.omo-tech')) {
    return 'OMO_FRONT';
  }

  if (origin.includes('app.wavesend')) {
    return 'WS_FRONT';
  }

  return 'APP_FRONT';
}

export function getPhpApiOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech')) {
    return 'https://go.pushwoosh.com';
  }

  return origin.replace('app.', 'go.');
}

export function getSsoPublicOrigin(): string {
  const { origin } = window.location;

  if (origin.includes('localhost')) {
    return 'https://sso.pushwoosh.com';
  }

  return origin.replace('app.', 'sso.');
}

export function getApiOrigin(apiDomain: string): string {
  const { origin } = window.location;

  if (origin.includes('localhost') || origin.includes('app.omo-tech') || origin.includes('app.pushwoosh')) {
    return `https://${apiDomain}.svc-nue.pushwoosh.com`;
  }

  if (origin.includes('app.wavesend')) {
    return origin.replace('app.', `${apiDomain}.svc.`);
  }

  return origin.replace('app.', `${apiDomain}.`);
}
